<template>
    <Modal
        :id="id"
        :required="false"
        :close="false"
        :show="false"
        class="shopping-cart"
    >
        <template #default>
            <div class="close">
                <div class="container">
                    <FormButton
                        class="l-button--icon-only l-button--secondary l-button--outlined close__button"
                        data-bs-toggle="modal"
                        :data-bs-target="`#${id}`"
                    >
                        <IconsClose />
                    </FormButton>
                </div>
            </div>
            <div v-if="lines.length">
                <div class="equal-small-2 shopping-cart__count">
                    {{ t('basket.cart') }}
                    <div class="equal-small-4">
                        {{ quantity }}
                    </div>
                </div>
                <div class="d-flex flex-column shopping-cart__items">
                    <ul>
                        <OrderCheckoutItem
                            v-for="item in lines"
                            :key="item.id"
                            :image="item.image"
                            :name="item.name"
                            :info="item.info"
                            :text="item.text"
                            :quantity="item.quantity"
                            :price="item.price"
                            :priceInvalid="item.priceInvalid"
                            :offer="item.offer"
                            :max="item.max"
                            :error="item.error"
                            :url="item.url"
                            :cut="item.cut"
                            :hide="item.hide"
                            :loading="basketStore.loading"
                            :upc="item.upc"
                            :product-url="item.htmlUrl"
                            :item_category="item.item_category"
                            :price_excl_tax="item.price_excl_tax"
                        />
                    </ul>
                </div>
                <div
                    class="d-flex justify-content-between equal-small-1 shopping-cart__discount"
                    v-for="discount in basketStore?.basket?.offer_discounts"
                >
                    <div>{{ discount.name }}</div>
                    <div>
                        - {{ basketStore?.basket?.currency }}
                        {{ discount.amount }}
                    </div>
                </div>
                <div
                    class="d-flex justify-content-between equal-base equal-base--tighter-bold shopping-cart__total"
                >
                    <div>{{ t('basket.subtotal') }}</div>
                    <div>
                        {{ basketStore?.basket?.currency }}
                        {{ basketStore?.basket?.total_incl_tax }}
                    </div>
                </div>
                <div v-if="messages?.length" class="shopping-cart__messages">
                    <ul>
                        <li v-for="message in messages" :key="message.id">
                            <Message
                                :message="message.message"
                                :class="message.class"
                            />
                        </li>
                    </ul>
                </div>
                <div
                    class="shopping-cart__help"
                    v-if="
                        authStore?.settings?.help_email ||
                        authStore?.settings?.help_phone
                    "
                >
                    <span class="equal-small-1 equal-small-1--bold-uppercase">
                        {{ t('basket.help') }}
                    </span>
                    <div class="shopping-cart__help-buttons">
                        <FormButton
                            v-if="authStore?.settings?.help_email"
                            class="l-button--secondary l-button--icon-only"
                            :size40="true"
                            component="a"
                            :href="
                                'mailto:' +
                                authStore?.settings?.help_email.trim()
                            "
                        >
                            <IconsMail />
                        </FormButton>
                        <FormButton
                            v-if="authStore?.settings?.help_phone"
                            class="l-button--secondary l-button--icon-only"
                            :size40="true"
                            component="a"
                            :href="
                                'tel:' + authStore?.settings?.help_phone.trim()
                            "
                        >
                            <IconsPhone />
                        </FormButton>
                    </div>
                </div>
                <FormButton
                    data-cy="toCheckout"
                    class="shopping-cart__button"
                    :label="t('basket.checkout')"
                    :to="checkoutRoute.fullPath"
                    :size40="true"
                    component="NuxtLink"
                    @click="closeModal"
                    v-if="!belowMinimumOrderValue"
                />
            </div>
            <div v-else>
                <div
                    class="equal-base equal-base--tighter-bold shopping-cart__empty"
                >
                    {{ t('basket.empty') }}
                </div>
                <FormButton
                    class="shopping-cart__button"
                    :label="t('basket.discover')"
                    component="NuxtLink"
                    v-if="authStore?.settings?.catalogue_page?.url"
                    @click="discoverProducts"
                    :size40="true"
                />
            </div>
        </template>
    </Modal>
</template>

<script setup>
import { useBasketStore } from '~/stores/basket';
import { computed } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { useBasketLines } from '~/composables/transformers/basket';
import { useCheckoutRoute } from '~/composables/useCheckoutRoute';
import { useTrackBeginCheckout } from '~/composables/gtm/useEcomTracking';

const props = defineProps({
    id: {
        type: String,
        default: 'cart',
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const basketStore = useBasketStore();
const authStore = useAuthStore();

const total = computed(() => {
    return basketStore?.basket?.total_incl_tax;
});

const { t, locale } = useI18n();

const checkoutRoute = useCheckoutRoute();

function discoverProducts() {
    closeModal();
    navigateTo(`/${locale.value}/`);
}

// todo this is hacky - better solve this in vue
// however it seems the modal is not reactive
const closeModal = () => {
    document.querySelector(`[data-bs-target='#${props.id}']`).click();
    const burger = document.getElementsByClassName('navbar-burger--active')[0];
    if (burger) {
        burger.parentElement.click();
    }
    useTrackBeginCheckout(
        basketStore?.lines,
        basketStore?.basket?.total_incl_tax,
    );
};

const lines = computed(() => {
    if (props.items && props.items.length) return props.items;
    return useBasketLines().value;
});

const belowMinimumOrderValue = computed(() => {
    return !basketStore?.basket?.passes_order_minimum;
});

const messages = computed(() => {
    if (!authStore.settings) return [];
    const res = [];
    if (basketStore?.basket?.free_shipping) {
        res.push({
            message: t('basket.freeShipping'),
            class: 'message--success',
        });
    } else {
        if (total.value < parseFloat(authStore.settings.shipping_free_above)) {
            res.push({
                message: t('basket.untilFreeShipping', {
                    amount: (
                        parseFloat(authStore.settings.shipping_free_above) -
                        total.value
                    ).toFixed(2),
                    currency: basketStore?.basket?.currency,
                }),
                class: 'message--warning',
            });
        }
        if (belowMinimumOrderValue.value) {
            res.push({
                message: t('basket.minOrderValue', {
                    currency: basketStore?.basket?.currency,
                    amount: parseFloat(
                        authStore.settings.min_order_amount,
                    ).toFixed(0),
                }),
                class: 'message--danger',
            });
        }
    }
    return res;
});

const quantity = computed(() => {
    return lines.value.reduce((sum, l) => sum + l.quantity, 0);
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 1200;

    @include media-breakpoint-down(560) {
        top: unset;
        bottom: 20px;
        height: auto;
    }

    .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    &__button {
        margin: auto 0 auto auto;

        @include media-breakpoint-down(560) {
            position: absolute;
            left: 50%;
            margin: 0 0 5px 35px;
        }
    }
}

.shopping-cart {
    :deep(&.modal) {
        @include media-breakpoint-down(560) {
            overflow: hidden;
        }
    }
    :deep(.modal-body) {
        padding: clamp(10px, 2vw, 20px) !important;
    }
    :deep(.layout__container) {
        @include media-breakpoint-down(560) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    :deep(.layout__row) {
        justify-content: end !important;
        @include media-breakpoint-down(560) {
            height: auto;
            width: 100vw;
        }
    }

    :deep(.layout__col) {
        height: 100vh;
        width: 400px;
        @include media-breakpoint-down(560) {
            height: auto;
        }
    }

    :deep(.modal-dialog) {
        align-items: start;

        @include media-breakpoint-down(560) {
            padding: 0 !important;
        }
    }

    :deep(.modal-content) {
        height: 100vh;
        max-height: unset;
        @include media-breakpoint-down(560) {
            height: auto;
        }
    }

    :deep(.modal-content-box) {
        margin-top: 80px;
        margin-bottom: 50px;

        @include media-breakpoint-down(560) {
            margin-top: 15px;
            margin-bottom: 90px;
            max-height: calc(100vh - 100px) !important;
            @supports (height: 100dvh) {
                max-height: calc(100dvh - 100px) !important;
            }
        }
    }

    :deep(.modal-close) {
        margin: 10px 0 20px auto !important;
    }

    &__count {
        z-index: 10;
        display: inline-flex;
        position: sticky;
        top: 15px;
        left: 30px;
        padding: 10px 15px 10px 20px;
        margin-bottom: 10px;
        background-color: $color-dark-02;
        height: 40px;
        border: 1px solid $color-dark-01;
        border-radius: 20px;

        div {
            height: 16px;
            line-height: 13px;
            margin-left: 5px;
            padding: 2px 5px;
            background-color: $color-dark-01;
            border-radius: 16px;
        }
    }

    &__items {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__discount {
        padding: 20px 0;
        border-bottom: 1px solid $color-dark-01;
    }

    &__total {
        padding: 20px 0;
    }

    &__messages {
        margin-bottom: 10px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__help {
        padding: 20px;
        margin-bottom: 25px;
        background-color: $color-dark-04;
        border-radius: 12px;
        text-align: center;
    }

    &__help-buttons {
        margin-top: 10px;
        a {
            display: inline-block;
        }
        a:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__empty {
        padding: 20px;
        margin-bottom: 25px;
        border: 1px solid $color-dark-disabled;
        border-radius: 12px;
        text-align: center;
    }

    &__button {
        width: 100%;
        max-width: unset;
    }
}
</style>
